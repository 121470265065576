<template>
  <div>
    <el-dialog
      :title="`Export ${companyName} Employees`"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="key === 'name' || key === 'email'"
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="exporting"
            @click="getExportData"
            :disabled="!form.format"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CorporateEmployeesExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    companyName: {
      type: String,
      default: "",
    },
    employees: {
      type: Array,
      default() {
        return [];
      },
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        name: "Name",
        email: "Email",
        status: "Status",
        gardener: "Gardener",
        home_address: "Address",
        phone_number: "Phone number",
        zone: "Zone",
        location_area: "Location area",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["name", "email"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["name", "email"];
      this.exporting = false;
      this.shouldShow = false;
    },

    getExportData() {
      this.exporting = true;
      this.formatExportData(this.employees);
    },

    formatExportData(data) {
      let updated = this.formatDateAndTime(this.dataTime, "do m, y");
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `${this.companyName}_Employees  \nDownloaded at - ${updated}\nExported at - ${date}`;
      let headers = {
        sn: "S/N",
      };
      let customerData = [];
      this.form.fields.forEach((key) => (headers[key] = this.fields[key]));
      customerData = data.map((customer, index) => {
        return {
          sn: index + 1,
          name: this.formatName(customer.name),
          email: customer.email.toLowerCase(),
          status: customer.status ? "Active" : "Inactive",
          home_address: customer.home_address,
          phone_number: this.formatPhoneNumber(customer.phone_number),
          gardener: customer.gardener,
          zone: customer.cluster,
          location_area: customer.location_area,
        };
      });
      this.exportData = {
        title,
        headers,
        data: customerData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
