<template>
  <el-dialog
    title="Change Menu Type"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <div v-loading="loading">
      <!-- {{ data }} -->
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Status"
              prop="menu_type_variation_id"
              :rules="validateField()"
            >
              <el-select
                v-model="form.menu_type_variation_id"
                filterable
                placeholder="Select a menu type"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in filteredMenuTypes"
                  :value="item.id"
                  :key="index"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :loading="changing" @click="changeMenuType"
          >Change</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateChangeStatus",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        menu_type_variation_id: "",
      },
      changing: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return this.$route.params.id;
    },
    menuTypes() {
      return this.$store.getters.menu_types;
    },
    filteredMenuTypes() {
      return this.menuTypes.filter((item) => item.name !== "Basic");
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.menu_type_variation_id = this.data.menu_type_variation_id;
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.form = {
        menu_type_variation_id: "",
      };
    },
    changeMenuType() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;
        const payload = {
          ...this.form,
          contact_person_email_address: this.data.contact_person_email_address,
        };
        corporate
          .update(this.customerId, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.changing = false;
              this.$emit("success");
              this.closeEvent();
            } else {
              this.changing = false;
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.changing = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 20px;
}
</style>
