<template>
  <div class="corporate-subscription">
    <div>
      <span class="font-xsm text-grey-tertiary">Subscription</span>
      <div>
        <eden-services-list
          :services="Object.keys(subscription.plan)"
          :config="subscription.plan"
          :subscription="subscription"
        />
        <!--        <span class="corporate-subscription&#45;&#45;amount">{{-->
        <!--          formatPrice(subscription.amount)-->
        <!--        }}</span>-->
      </div>
    </div>
    <el-button type="text" @click="viewSubscriptions">
      View subscription cycle</el-button
    >
  </div>
</template>

<script>
export default {
  name: "CustomerCorporateSubscription",
  props: {
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      service: {},
    };
  },
  methods: {
    viewSubscriptions() {
      this.$router.push({ name: "customer.corporate-subscriptions" });
    },
  },
};
</script>
<style lang="scss" scoped>
.corporate-subscription {
  padding: 17px 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #ebeef5;
  border-top: 0;
  width: 320px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  color: var(--eden-grey-secondary);

  span.font-xsm {
    margin-bottom: 4px;
    display: block;
  }

  &--amount {
    margin-left: 20px;
    display: inline-block;
    color: var(--eden-grey-primary);
    font-weight: 500;
  }

  @media (min-width: 850px) {
    width: 100%;
  }
}
</style>
