<template>
  <el-dialog
    title="Order Date Adjustment"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <div v-loading="loading">
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex" :gutter="10" class="is-flex-wrap">
          <el-col :span="12">
            <el-form-item
              label="Current delivery date"
              prop="initial_delivery_date"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="form.initial_delivery_date"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd MMM, yyyy"
                :picker-options="{
                  disabledDate: disabledDates,
                }"
                prefix-icon="eden-icon--calendar"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="New delivery date" prop="new_delivery_date">
              <el-date-picker
                v-model="form.new_delivery_date"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd MMM, yyyy"
                :picker-options="{
                  disabledDate: disabledDates,
                }"
                prefix-icon="eden-icon--calendar"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :loading="changing" @click="adjustDate"
          >Adjust
        </el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateChangeStatus",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    employees: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        initial_delivery_date: null,
        new_delivery_date: null,
      },
      changing: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.form = {
        initial_delivery_date: null,
        new_delivery_date: null,
      };
    },
    disabledDates(time) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return time < today.getTime();
    },
    adjustDate() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;
        if (this.employees) {
          this.adjustEmployeeDate();
        } else {
          this.adjustCompanyDate();
        }
        return true;
      });
    },
    adjustCompanyDate() {
      const payload = {
        ...this.form,
        user_corporation_id: this.customerId,
      };
      corporate
        .reschedule(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.changing = false;
            this.$emit("success");
            this.closeEvent();
          } else {
            this.changing = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.changing = false;
          this.$message.error(error.response.data.message);
        });
      return true;
    },
    adjustEmployeeDate() {
      const payload = {
        ...this.form,
        user_ids: this.employees,
      };
      console.log(payload, "payload");
      corporate
        .employeeReschedule(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.changing = false;
            this.$emit("success");
            this.closeEvent();
          } else {
            this.changing = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.changing = false;
          this.$message.error(error.response.data.message);
        });
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 20px;
}
</style>
