<template>
  <div>
    <el-drawer :visible.sync="shouldShow" direction="rtl" @close="closeEvent">
      <template slot="title">
        <span class="font-md text-grey-tertiary">Add Employee</span>
      </template>
      <div class="el-drawer--content" v-loading="loading">
        <div class="el-drawer--content__body">
          <el-form :model="form" label-position="top" ref="form">
            <el-row type="flex" :gutter="15" class="is-flex-wrap">
              <el-col :md="12" :lg="12">
                <el-form-item
                  label="First name"
                  prop="first_name"
                  :rules="validateName()"
                >
                  <el-input
                    type="text"
                    v-model="form.first_name"
                    placeholder="Enter first name"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="12">
                <el-form-item
                  label="Last name"
                  prop="last_name"
                  :rules="validateName()"
                >
                  <el-input
                    type="text"
                    v-model="form.last_name"
                    placeholder="Enter last name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Email address"
                  prop="email"
                  :rules="validateEmail()"
                >
                  <el-input
                    type="text"
                    v-model="form.email"
                    placeholder="you@mail.com"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Phone number"
                  prop="phoneNo"
                  :rules="validatePhone('Phone number')"
                >
                  <eden-phone-input :input.sync="form.phoneNo" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="is-flex-wrap" :gutter="15">
              <el-col :md="15">
                <el-form-item
                  label="Location area"
                  prop="location_area_id"
                  :rules="validateField()"
                >
                  <el-select
                    v-model="form.location_area_id"
                    filterable
                    placeholder="Select location"
                  >
                    <el-option
                      v-for="location in locations"
                      :key="location.id"
                      :label="location.name"
                      :value="location.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="9">
                <el-form-item
                  label="Gender"
                  prop="gender"
                  :rules="validateField()"
                >
                  <el-select v-model="form.gender" placeholder="Select">
                    <el-option label="Male" value="male" />
                    <el-option label="Female" value="female" />
                    <el-option label="Non binary" value="non-binary" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Address"
                  prop="address"
                  :rules="validateField()"
                >
                  <el-input
                    type="textarea"
                    rows="4"
                    v-model="form.address"
                    placeholder="Enter the customer’s full address"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Gardener in charge"
                  prop="gardener_id"
                  :rules="validateField()"
                >
                  <el-select
                    v-model="form.gardener_id"
                    placeholder="Select gardener"
                    filterable
                  >
                    <el-option
                      v-for="(gardener, index) in gardeners"
                      :key="index"
                      :label="gardener.name"
                      :value="gardener.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!loading" type="flex">
              <el-col :span="24">
                <el-form-item
                  label=""
                  prop="services"
                  :rules="validateField('Services')"
                >
                  <subscription-configuration
                    :config="form.plan"
                    @set-config="setPlan"
                    :menu-type-variation="{
                      employee: 'fixed',
                      menu: corporateMenuType,
                    }"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Start date">
                  <el-date-picker
                    v-model="form.start_date"
                    value-format="yyyy-MM-dd"
                    type="date"
                    format="dddd, dd MMMM, yyyy"
                    prefix-icon="eden-icon--calendar"
                    :picker-options="{
                      disabledDate: disabledDates,
                    }"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="el-drawer--footer is-align-center">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :loading="adding" @click="add">Add</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import corporate from "@/requests/customers/corporate";
import * as actions from "@/store/action-types";

export default {
  name: "CorporateEmployeesForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      loading: false,
      form: {
        avatar:
          "https://res.cloudinary.com/eden-life-inc/image/upload/v1593165834/lighthouse/avatar-placeholder_uxleqd.png",
        first_name: "",
        last_name: "",
        email: "",
        phoneNo: "",
        address: "",
        location_area_id: "",
        gender: "",
        gardener_id: "",
        start_date: "",
        services: [],
        plan: {},
        role: "customer",
        user_corporation_id: null,
      },
      adding: false,
      corporateMenuType: "",
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },

      set(value) {
        this.$emit("update:show", value);
      },
    },
    locations() {
      return this.$store.getters.location_areas_list;
    },
    gardeners() {
      const gardeners = [...new Set(this.$store.getters.gardeners_list)];
      return this.sortList(gardeners, "name");
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.loading = true;
        console.log(this.company);
        this.corporateMenuType = {
          name: this.company.menu_type_variation.name,
          id: this.company.menu_type_variation.id,
        };
        this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
        this.$store.dispatch(actions.GET_GARDENERS_LIST).then(() => {
          this.form.location_area_id = this.company.location_area_id;
          this.form.address = this.company.company_address;
          this.form.plan = this.company.plan;
          this.form.user_corporation_id = parseInt(this.$route.params.id);
          this.loading = false;
        });
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    setPlan({ config, amount }) {
      this.form.services = Object.keys(config)
      this.form.plan = config;
      this.form.amount = amount;
    },
    formatForm() {
      const payload = { ...this.form };
      payload.full_name = payload.first_name + " " + payload.last_name;
      payload.plan = JSON.stringify(payload.plan);
      return payload;
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const payload = this.formatForm();

        corporate
          .addEmployee(payload)
          .then((response) => {
            if (response.data.status) {
              this.closeEvent();
              this.$emit("success");
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error({
                message: errorMessage.errors[errorKeys[0]].join(""),
                duration: 4000,
              });
            } else {
              this.$message.error({
                message: errorMessage.message,
                duration: 4000,
              });
            }
          });
        return true;
      });
    },
  },
};
</script>
