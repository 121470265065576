<template>
  <div>
    <div class="corporate--summary">
      <eden-information-card :title="'General Information'">
        <template slot="content">
          <el-table
            :class="'general'"
            :data="general"
            :show-header="false"
            :span-method="arraySpanMethod"
          >
            <el-table-column>
              <template slot-scope="scope">
                <template v-if="scope.$index < 2">
                  <span class="label font-xsm">{{ scope.row.label }}</span>
                  <p class="text font-sm">
                    {{ scope.row.text }}
                  </p>
                </template>
                <template v-else>
                  <el-table
                    :class="'inner-table'"
                    :data="scope.row.data"
                    :show-header="false"
                  >
                    <el-table-column>
                      <template slot-scope="scope">
                        <span class="label font-xsm">{{
                          scope.row.label
                        }}</span>
                        <p class="text font-sm">
                          {{ scope.row.text }}
                        </p>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </template>
            </el-table-column></el-table
          >
        </template>
      </eden-information-card>

      <eden-information-card :title="'Contact Person'">
        <template slot="content">
          <el-table :data="contact" :show-header="false">
            <el-table-column>
              <template slot-scope="scope">
                <span class="label font-xsm">{{ scope.row.label }}</span>
                <p v-if="scope.row.text" class="text font-sm">
                  {{ scope.row.text }}
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column></el-table
          >
        </template>
      </eden-information-card>
    </div>

    <customer-corporate-subscription :subscription="subscription" />
  </div>
</template>

<script>
import CustomerCorporateSubscription from "./CustomerCorporateSubscription.vue";

export default {
  components: { CustomerCorporateSubscription },
  name: "CustomerCorporateSummary",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      contact: [
        {
          label: "Name",
          text:
            `${this.data.contact_person_first_name} ${this.data.contact_person_last_name}` ||
            "-",
        },
        {
          label: "Email Address",
          text: this.data.contact_person_email_address || "-",
        },
        {
          label: "Phone number",
          text:
            this.formatPhoneNumber(this.data.contact_person_phone_number) ||
            "-",
        },
      ],
      subscription: {
        amount: this.data.subscription_amount,
        plan: { ...this.data.plan },
        menuType: this.data.menu_type,
      },
    };
  },
  computed: {
    general() {
      return [
        {
          label: "Next charge date",
          text: this.formatDate(this.data.next_charge_date, "do m, y") || "-",
        },
        {
          label: "Address",
          text: this.data.company_address || "-",
        },

        {
          data: [
            {
              label: "Joined on",
              text:
                this.formatDateAndTime(this.data.created_at, "do m, y") || "-",
            },
            {
              label: "Last updated",
              text:
                this.formatDateAndTime(this.data.updated_at, "do m, y") || "-",
            },
          ],
        },
      ];
    },
  },
  watch: {
    data() {
      console.log(this.data);
    },
  },
  methods: {
    arraySpanMethod({ rowIndex }) {
      if (rowIndex === 2) {
        return [1, 2];
      } else if (rowIndex === 3) {
        return [3, 4];
      } else {
        return [1, 4];
      }
    },
  },
};
</script>

<style lang="scss">
.corporate--summary {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.general {
  tr:last-child {
    td {
      padding: 0;

      .cell {
        padding: 0;
      }
    }
  }
}

.inner-table {
  tbody {
    display: flex;

    tr {
      width: 50%;
      padding: 16px;

      &:nth-child(2) {
        border-left: 1px solid #ebeef5;
      }
    }
  }
}

.el-table .el-table__cell {
  padding: 18px 0;
}

@media (min-width: 768px) {
  .information-card {
    margin-bottom: 0 !important;
  }
  .information-card:first-child {
    border-radius: 0;
  }
  .information-card:nth-child(2) {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (min-width: 996px) {
  .corporate--summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
