<template>
  <div class="wallet-card">
    <div class="wallet-card__information">
      <div class="wallet-card__information-text">
        <div class="value font-lg text-bold">
          {{ formatPrice(customer.wallet_balance) }}
        </div>
        <div class="title font-sm">Wallet Balance</div>
      </div>

      <div class="wallet-card__information-text">
        <div class="value font-lg text-bold">
          {{ formatPrice(customer.available_wallet_balance) }}
        </div>
        <div class="title font-sm">Available to spend</div>
      </div>
    </div>
    <div class="wallet-card--action">
      <router-link
        class="wallet-card__action font-sm"
        :to="{
          name: 'customers.corporate.wallet',
          params: { id: customer.id },
        }"
        >Open Wallet</router-link
      >
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "CustomerCorporateWalletCard",
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.wallet-card {
  background-color: var(--eden-grey-secondary);
  color: white;
  border-bottom-left-radius: 16px;
  &__information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    &-text {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      padding: 12px 10px;
    }
    .title {
      margin-top: 7px;
    }
  }
&--action {
  padding: 12px;
}
  &__action {
    color: var(--eden-green-quinary);
    cursor: pointer;
  }
}
</style>
  