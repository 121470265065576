<template>
  <div>
    <el-dialog
      title="Enter Amount"
      :visible.sync="shouldShow"
      @close="closeEvent"
      append-to-body
      width="30%"
    >
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Amount" prop="amount" :rules="validateField()">
              <el-input
                placeholder="Enter amount to be paid"
                type="number"
                v-model="form.amount"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"></el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :disabled="!form.amount" @click="submitForm"
          >Continue</el-button
        >
      </span>
    </el-dialog>

    <employee-fund-wallet-confirmation
      v-if="showConfirmation"
      :show.sync="EmployeeFundWalletConfirmation"
      :amount="amountPayment"
      :data="data"
      :customer="customer"
      :employees="employees"
      @wallet-updated="onWalletUpdated"
    />
  </div>
</template>
  
      <script>
//   import customer from "@/requests/customers/customer/actions";

import EmployeeFundWalletConfirmation from "@/components/Customers/Corporate/Employees/FundWallet/EmployeeFundWalletConfirmation";

export default {
  name: "EmployeesFundWallet",
  components: {
    EmployeeFundWalletConfirmation,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    employees: {
      type: Array,
      default() {
        return [];
      },
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      linking: false,
      showConfirmation: false,
      EmployeeFundWalletConfirmation: false,
      form: {
        amount: 0,
      },
      amountPayment: 0,
      localData: {},
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerName() {
      return this.formatName(this.customer.name);
    },
  },
  watch: {
    form: {
      handler(form) {
        this.amountPayment = parseFloat(form.amount);
      },
      deep: true,
    },
  },
  mounted() {
    this.localData = { ...this.data };
  },

  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    onWalletUpdated(updatedCompany) {
      // Update the company data in the parent component
      this.localData = updatedCompany;
      this.$emit("wallet-updated", updatedCompany);
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.shouldShow = false;
        this.showConfirmation = true;
        this.EmployeeFundWalletConfirmation = true;
      }
    },
  },
};
</script>
      
      <style lang="scss" scoped></style>
      