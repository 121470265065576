<template>
  <el-dialog
    title="Change Company Status"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <div v-loading="loading">
      <!-- {{ data }} -->
      <!-- {{ employees }} -->
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Status"
              prop="customer_status"
              :rules="validateField()"
            >
              <el-select
                v-model="form.customer_status"
                placeholder="Select a status type from the dropdown below"
              >
                <el-option label="Subscriber" value="subscriber" />
                <el-option label="Paused" value="paused" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :loading="changing" @click="changeStatus"
          >Change</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateChangeStatus",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    employees: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        customer_status: "",
      },
      changing: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.customer_status = this.data.customer_status;
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.form = {
        customer_status: "",
      };
    },
    changeStatus() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;
        if (this.employees) {
          this.changeEmployeeStatus();
        } else {
          this.changeCompanyStatus();
        }
        return true;
      });
    },
    changeCompanyStatus() {
      const payload = {
        ...this.form,
        contact_person_email_address: this.data.contact_person_email_address,
      };
      corporate
        .update(this.customerId, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.changing = false;
            this.$emit("success");
            this.closeEvent();
          } else {
            this.changing = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.changing = false;
          this.$message.error(error.response.data.message);
        });
      return true;
    },
    changeEmployeeStatus() {
      const payload = {
        subscriber_status: this.form.customer_status,
        customer_ids: this.employees,
      };
      corporate
        .updateStatus(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.changing = false;
            this.$emit("success");
            this.closeEvent();
          } else {
            this.changing = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.changing = false;
          this.$message.error(error.response.data.message);
        });
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 20px;
}
</style>
