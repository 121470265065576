<template>
  <el-drawer
    :title="title"
    :visible.sync="shouldShow"
    direction="rtl"
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Email" prop="email" :rules="validateEmail()">
                <el-input type="text" v-model="form.email" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <template
            v-if="action !== 'edit' || allowAccessFor(['superadmin', 'admin'])"
          >
            <el-row type="flex">
              <el-col :span="24">
                <subscription-configuration
                  :config="form.plan"
                  @set-config="setPlan"
                  :menu-type-variation="menuTypeVariation"
                />
              </el-col>
            </el-row>
          </template>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Subscription Amount" prop="amount">
                <el-input v-model="form.amount" v-number readonly />
              </el-form-item>
            </el-col>
          </el-row>
          <template>
            <el-row v-if="action !== 'edit'" type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Start date"
                  prop="start_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.start_date"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    placeholder="Select start date"
                    :picker-options="{
                      disabledDate: disabledDates,
                    }"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-else type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Next charge date"
                  prop="next_charge_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.next_charge_date"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    placeholder="Select next charge date"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-center">
      <el-button
        v-if="action === 'edit'"
        type="primary"
        :loading="updating"
        @click="update"
        :disabled="invalidSelection"
        >Save changes</el-button
      >
      <el-button
        v-else
        type="primary"
        :loading="adding"
        @click="add"
        :disabled="invalidSelection"
        >Add</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import subscriptions from "@/requests/subscriptions/index";

export default {
  name: "SubscriptionForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      adding: false,
      updating: false,
      customers: [],
      form: {
        name: "",
        email: "",
        amount: 0,
        services: [],
        plan: {},
        start_date: "",
        next_charge_date: "",
      },
      invalidSelection: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.action !== "edit" ? "Add Subscription" : "Edit Subscription";
    },
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.action === "add") {
          this.form.name = this.formatName(this.customer.name);
          this.form.email = this.stringTrim(this.customer.email);
        } else {
          this.form = JSON.parse(JSON.stringify(this.subscription));
        }
      }
    },
    form: {
      deep: true,
      handler() {
        for (let service in this.form.plan) {
          this.invalidSelection = !this.form.plan[service].amount;
        }
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
      this.form.services = [];
      this.form.plan = {};
    },
    setPlan({ config, amount }) {
      let service = Object.keys(config)[0];
      if (service === "meal") {
        this.form.plan = config;
        // this.form.plan = {meal: config[service]}
        // this.form.menu_type = config.menu_type;
        // this.form.menu_type_variation_id = config.menu_type.id
        // } else {
        //   this.form.plan = config;
      }
      this.form.amount = amount;
      this.form.plan = config;
    },
    disabledDates(time) {
      if (this.allowAccessFor([])) {
        return false;
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    formatForm() {
      const payload = { ...this.form };
      console.log(payload)
      if (payload.service === "meal") {
      let menuType = this.menuTypeVariation.menuOptions.find(
        (elm) => elm.id == this.form.plan.meal.menu_type_variation_id,
        
      );
      if (menuType) {
        payload.name = menuType.name;
        payload.menu_type_variation_id = menuType.id;
      }
    }
      const sortedPlan = this.sortObject(payload.plan);
      payload.menu_type_variation_id;
      payload.corporate_id =Number( this.$route.params.id)
      payload.plan = JSON.stringify(sortedPlan);
      payload.new_onboarding_flow = true;
      delete payload.services;
      delete payload.menu_type
      return payload;
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const payload = this.formatForm();
        console.log(payload)
        subscriptions
          .add(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.adding = false;
              this.$emit("updated");
              this.closeEvent();
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = this.formatForm();
        subscriptions
          .update(this.subscription.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
              this.$emit("updated");
              this.closeEvent();
            } else {
              this.$message.error(response.data.message);
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 20px 0 10px;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #21312a;
  }
}

.eden-plan--summary {
  width: 100%;
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 20px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  span {
    color: #798b83;
    width: 50%;
  }

  p {
    color: #21312a;
    font-size: 1rem;
  }
}

.eden-customer-service {
  margin-bottom: 40px;
}
</style>
