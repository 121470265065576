<template>
  <div class="corporate-customer-profile">
    <div v-if="loading">Loading</div>
    <template v-else>
      <eden-page-header :title="'Customers'" :subtitle="customerName">
        <template slot="actions">
          <div class="is-flex is-flex-wrap align-center">
            <el-dropdown :placement="'bottom'" @command="actionsCommand">
              <el-button type="plain">
                Actions <i class="eden-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-for="(action, i) in actions">
                  <el-dropdown-item
                    v-if="allowAccessFor(action.access)"
                    :key="i"
                    :command="action.command"
                    :disabled="action.disabled"
                  >
                    {{ action.label }}</el-dropdown-item
                  >
                </template>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown :placement="'bottom'" @command="actionsCommand">
              <el-button type="primary">
                Subscription <i class="eden-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-for="(action, i) in subscriptionActions">
                  <el-dropdown-item
                    v-if="allowAccessFor(action.access)"
                    :key="i"
                    :command="action.command"
                    :disabled="action.disabled"
                  >
                    {{ action.label }}</el-dropdown-item
                  >
                </template>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </eden-page-header>
      <customer-corporate-profile
        ref="profile"
        :data="customer"
        @wallet-updated="handleWalletUpdate"
      />
      <component
        :is="action.component"
        :show.sync="action.visibility"
        :data="customer"
        @success="getCustomer"
      />
      <subscription-form
        :show.sync="showSubscriptionForm"
        action="edit"
        @updated="getCustomer"
        :subscription="customerSubscription"
        @success="updateChargeDate"
        :menu-type-variation="menuType"
      />
      <subscription-cycle-set
        :show.sync="showSubscriptionCycleSet"
        :customer-email="customerEmail"
        @success="updateChargeDate"
        :customer-type="'corporate'"
      />
      <customer-corporate-change-status
        :show.sync="showChangeStatus"
        :data="customer"
        @success="getCustomer"
      />
      <customer-corporate-menu-type
        :show.sync="showMenuType"
        :data="customer"
        @success="getCustomer"
      />
      <customer-corporate-batch-order-adjustment
        :show.sync="showBatchOrder"
        @success="getCustomer"
      />
    </template>
  </div>
</template>

<script>
import corporate from "@/requests/customers/corporate";
import CustomerCorporateProfile from "@/components/Customers/Corporate/Profile/CustomerCorporateProfile.vue";
import CustomerCorporateInvoiceForm from "@/components/Customers/Corporate/Actions/Invoice/CustomerCorporateInvoiceForm";
import CustomerCorporateOrdersPause from "@/components/Customers/Corporate/Actions/CustomerCorporateOrdersPause";
import CustomerCorporateEmployeesLink from "@/components/Customers/Corporate/Actions/CustomerCorporateEmployeesLink";
import SubscriptionForm from "@/components/Subscriptions/Subscription/SubscriptionForm";
import SubscriptionCycleSet from "@/components/Subscriptions/Subscription/SubscriptionCycleSet";
import CustomerCorporateSubscriptionRenewal from "@/components/Customers/Corporate/Actions/CustomerCorporateSubscriptionRenewal";
import CustomerCorporateChangeStatus from "@/components/Customers/Corporate/Actions/CustomerCorporateChangeStatus.vue";
import CustomerCorporateMenuType from "@/components/Customers/Corporate/Actions/CustomerCorporateMenuType.vue";
import CustomerCorporateBatchOrderAdjustment from "@/components/Customers/Corporate/Actions/CustomerCorporateBatchOrderAdjustment.vue";

export default {
  components: {
    CustomerCorporateProfile,
    CustomerCorporateInvoiceForm,
    CustomerCorporateOrdersPause,
    CustomerCorporateEmployeesLink,
    CustomerCorporateSubscriptionRenewal,
    CustomerCorporateChangeStatus,
    CustomerCorporateMenuType,
    SubscriptionForm,
    SubscriptionCycleSet,
    CustomerCorporateBatchOrderAdjustment,
  },
  name: "CustomerCorporate",

  data() {
    return {
      loading: false,
      actions: [
        {
          icon: "",
          label: "Generate Payment Link",
          command: "generate-payment-link",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Generate Invoice",
          command: "invoice-form",
          access: ["superadmin", "admin", "growth"],
          disabled: true,
        },
        {
          icon: "",
          label: "Pause/Unpause All Orders",
          command: "orders-pause",
          access: ["superadmin", "admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Link Employee(s)",
          command: "employees-link",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Change Company Status",
          command: "change-status",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Change Menu Type",
          command: "change-menu-type",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Adjust Order Date",
          command: "adjust-order",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Edit Profile",
          command: "edit-profile",
          access: ["admin", "gardener", "gardener_pro"],
        },
      ],
      subscriptionActions: [
        {
          icon: "",
          label: "Renew subscription",
          command: "subscription-renewal",
          access: ["superadmin", "admin", "gardener", "gardener_pro"],
          // disabled: true,
        },
        {
          icon: "",
          label: "Edit subscription",
          command: "subscription-form",
          access: ["superadmin", "admin", "gardener_pro"],
        },
        {
          icon: "",
          label: "Set Cycle",
          command: "subscription-cycle-set",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Update amount",
          command: "amount",
          access: ["superadmin", "admin", "gardener_pro"],
        },
      ],
      action: {
        command: "",
        component: "",
        visibility: false,
      },
      tab: "overview",
      customer: {},
      showSubscriptionForm: false,
      showSubscriptionCycleSet: false,
      showChangeStatus: false,
      showMenuType: false,
      showBatchOrder: false,
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    customerName() {
      return this.customer.company_name
        ? this.formatName(this.customer.company_name)
        : "";
    },
    customerEmail() {
      return this.customer.contact_person_email_address?.toLowerCase();
    },
    menuTypeVariation() {
      if (this.customer.menu_type_variation_id === null) {
        return {};
      }
      let menus = this.$store.getters.menu_variations;
      if (menus.length) {
        let userVariation = menus.filter((menu) => {
          if (this.customer.menu_type_variation_id === menu.id) {
            return menu;
          }
        });
        if (userVariation.length) {
          return {
            employee: "fixed",
            menu: {
              name: userVariation[0].name,
              id: this.customer.menu_type_variation_id,
            },
            menuOptions: menus,
          };
        }
      }
      return {};
    },
    customerSubscription() {
      // const subscription =
      //   this.customer.next_billing_date?.length &&
      //   this.customer.next_billing_date[0];
      // console.log(subscription, "here");
      return {
        id: this.customer.subscription_id,
        name: this.formatName(this.customer.company_name),
        email: this.customer.contact_person_email_address?.toLowerCase(),
        status: "active",
        plan: this.customer.plan,
        services: Object.keys(this.customer.plan),
        amount: this.customer.subscription_amount,
        start_date: this.customer.start_date,
        next_charge_date: this.customer.next_charge_date,
        menu_type: this.menuTypeVariation,
      };
    },
    menuType() {
      return this.customerSubscription.menu_type;
    },
  },
  created() {
    this.getCustomer();
  },
  methods: {
    handleWalletUpdate(updatedData) {
      this.customer = updatedData;
    },
    getCustomer() {
      this.loading = true;
      corporate
        .get(this.customerId)
        .then((response) => {
          this.customer = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    actionsCommand(command) {
      if (command === "amount") {
        this.$refs.profile.$refs.employees.updateSubscriptionAmount();
        return;
      }
      if (command === "subscription-cycle-set") {
        this.showSubscriptionCycleSet = true;
        return;
      }
      if (command === "change-status") {
        this.showChangeStatus = true;
        return;
      }
      if (command === "change-menu-type") {
        this.showMenuType = true;
        return;
      }
      if (command === "adjust-order") {
        this.showBatchOrder = true;
        return;
      }
      // if (command === "subscription-renewal") {
      //   this.showSubscriptionCycleSet = true;
      //   return;
      // }
      if (command === "subscription-form") {
        this.showSubscriptionForm = true;
        return;
      }
      if (command === "edit-profile") {
        this.$router.push({
          name: "customers.corporate-edit",
          params: { id: this.customerId },
        });
        return;
      }
      this.action.command = command;
      this.action.component = `customer-corporate-${command}`;
      setTimeout(() => {
        this.action.visibility = true;
      }, 200);
    },
    actionsUpdate(data) {
      const { command } = this.action;
      switch (command) {
        case "status-change":
          this.updateStatus(data);
          break;
        case "subscription-cycle-set":
          this.updateChargeDate(data);
          break;
        default:
          break;
      }
    },
    updateStatus(status) {
      this.customer.customer_status = status
        ? status
        : this.customer.customer_status;
    },
    updateChargeDate(date) {
      this.customer.next_charge_date = date;
    },
  },
};
</script>

<style lang="scss" scoped>
$-summary-width: 320px;

.individual-customer-profile {
  .body {
    margin-top: 45px;
    .detail {
      margin-left: 64px;
      width: calc(100% - #{$-summary-width});
    }
  }

  @media (max-width: 992px) {
    .body {
      flex-wrap: wrap;
      .detail {
        margin: 0;
        width: 100%;
      }
    }
  }
}
</style>
