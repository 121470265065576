<template>
  <div>
    <div class="corporate--profile-body">
      <eden-information-card :class="'main'">
        <template slot="content">
          <div class="main--information">
            <img
              :src="getAvatar(data.avatar)"
              alt="avatar"
              class="avatar-img"
            />
            <h6 class="font-lg name">{{ data.company_name }}</h6>
            <h6 class="email font-sm text-grey-tertiary">
              {{ data.contact_person_email_address }}
            </h6>

            <h6 class="email font-sm text-grey-tertiary">
              {{ data.company_address }}
            </h6>

            <div class="profile-tags is-flex align-center justify-center">
              <el-tag :class="'small'" :type="setType(data.customer_status)">{{
                formatText(data.customer_status)
              }}</el-tag>
            </div>
            <customer-corporate-wallet-card :customer="data" />
          </div>
        </template>
      </eden-information-card>
      <customer-corporate-summary :data="data" />
    </div>

    <customer-corporate-employees
      ref="employees"
      :company="data"
      @wallet-updated="updateData"
    />
  </div>
</template>

<script>
import CustomerCorporateEmployees from "../Employees/CustomerCorporateEmployees.vue";
import CustomerCorporateSummary from "./CustomerCorporateSummary.vue";
import CustomerCorporateWalletCard from "./CustomerCorporateWalletCard.vue";
export default {
  name: "CustomerCorporateProfile",
  components: {
    CustomerCorporateSummary,
    CustomerCorporateWalletCard,
    CustomerCorporateEmployees,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    updateData(updatedData) {
      this.$emit("wallet-updated", updatedData);
    },
  },
};
</script>

<style lang="scss" scoped>
.corporate--profile-body {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 44px;
}
.information-card {
  width: 320px;
}
.main--information {
  text-align: center;

  .name {
    font-weight: 400;
    margin-bottom: 7px;
  }

  .email {
    font-weight: 400;
    margin-bottom: 8px;
  }

  .profile-tags {
    margin: 8px 0 28px 0;
  }
  .el-button {
    margin: auto;
  }
}

@media (min-width: 768px) {
  .information-card {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;

    &.main {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }
}

@media (min-width: 996px) {
  .corporate--profile-body {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0;
  }
}
</style>
