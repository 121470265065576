<template>
  <div class="corporate-employees">
    <eden-table-actions
      :title="`${employees.length} Employees`"
      @search="search"
    >
      <template slot="actions">
        <template v-if="selectedEmployees.length" class="mt-3">
          <div class="is-flex is-align-center">
            <el-button type="primary" @click="showEmployeePauses = true">
              Pause/Unpause Orders
            </el-button>
          </div>

          <div class="is-flex is-align-center mx-2">
            <el-button type="primary" @click="showEmployeeFundWallet = true">
              Fund Wallet
            </el-button>
          </div>
          <div class="is-flex is-align-center mr-2">
            <el-button type="primary" @click="showChangeStatus = true">
              Change Status
            </el-button>
          </div>
          <div class="is-flex is-align-center mr-2">
            <el-button type="primary" @click="showBatchOrder = true">
              Adjust Order Date
            </el-button>
          </div>
        </template>
        <div class="is-flex align-center justify-end">
          <el-button
            type="plain"
            icon="eden-icon-upload"
            :disabled="loading || !employees.length"
            @click="exportEmployees"
          >
            Export
          </el-button>

          <el-dropdown class="dropdown-with-buttons" @command="addEmployee">
            <el-button type="primary">
              Add Employee
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                command="single"
                icon="el-icon-circle-plus-outline"
                >Single employee
              </el-dropdown-item>
              <el-dropdown-item command="bulk" icon="eden-icon-upload"
                >Bulk upload
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </eden-table-actions>

    <template v-if="loading">Loading...</template>
    <el-table
      v-else
      style="width: 100%"
      :data="employees"
      @selection-change="setSelectedEmployees"
    >
      <el-table-column type="selection" :selectable="isNotChurned" width="55">
      </el-table-column>
      <el-table-column width="200">
        <template slot="header">
          <div class="table--header">
            <span>Name</span>
          </div>
        </template>
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'customers.individual',
              params: { id: scope.row.id },
            }"
          >
            <span class="font-sm text-primary">
              {{ formatName(scope.row.name) }}</span
            ></router-link
          >
          <span class="font-xsm lowercase">{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="eden-table-header"> Phone </span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatPhoneNumber(scope.row.phone_number) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Status</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm table--status">
            <el-tag :type="setType(scope.row.customer_status)">
              {{ formatText(scope.row.customer_status) }}
            </el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Services</span>
          </div>
        </template>
        <template slot-scope="scope">
          <eden-services-list
            v-if="scope.row.services.length"
            :services="scope.row.services"
          />
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Gardener</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{
            scope.row.gardener || "No assigned gardener"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Zone/Location Area</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.location_area }}</span>
          <span class="font-xsm">{{ scope.row.cluster || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="allowAccessFor(['superadmin'])" width="60">
        <template slot-scope="scope">
          <el-dropdown @command="command" class="more">
            <span class="el-dropdown-link">
              <i class="eden-icon-more" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                icon="el-icon-document"
                v-if="allowAccessFor(['superadmin', 'gardener_pro', 'gardener'])"
                :command="{
                  action: 'fund-wallet',
                  id: scope.row.id,
                  index: scope.$index,
                  customer: scope.row,
                }"
                >Fund Wallet</el-dropdown-item
              >
              <el-dropdown-item
                icon="eden-icon-delete"
                :command="{
                  action: 'delete',
                  id: scope.row.id,
                  index: scope.$index,
                  customer: scope.row,
                }"
                >Delete Employee</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <corporate-employees-export
      :show.sync="exporting"
      :companyName="company.company_name"
      :employees="employees"
      :data-time="pageDataTime"
    />
    <corporate-employees-form
      :show.sync="employeeForm"
      :company="company"
      @success="updateEmployeesList"
    />
    <customer-corporate-orders-pause
      :show.sync="showEmployeePauses"
      :data="this.company"
      :employees="selectedEmployees"
      @success="getPauses"
    />
    <customer-corporate-change-status
      :show.sync="showChangeStatus"
      :data="company"
      :employees="selectedEmployees"
      @success="getEmployees"
    />
    <customer-corporate-batch-order-adjustment
      :show.sync="showBatchOrder"
      :employees="selectedEmployees"
      @success="getEmployees"
    />
    <eden-upload-csv
      :title="csv.title"
      :template-url="csv.templateUrl"
      :fields="csv.fields"
      :url="csv.url"
      :show.sync="showBulkEmployeeAdd"
      :uploading.sync="uploading"
      @mapped="uploadCustomers"
    />
    <customer-employees-delete
      :show.sync="showCustomerCorporateDelete"
      :customer="employeeDetails.data"
      @success="updateEmployees"
    />
    <employees-fund-wallet
      :show.sync="showEmployeeFundWallet"
      :data="this.company"
      :customer="employeeDetails.data"
      :employees="selectedEmployees"
      @wallet-updated="$emit('wallet-updated', $event)"
    />
  </div>
</template>

<script>
import corporate from "@/requests/customers/corporate";
import subscriptions from "@/requests/subscriptions";
import * as actions from "@/store/action-types";
import CorporateEmployeesExport from "@/components/Customers/Corporate/Employees/CorporateEmployeesExport.vue";
import CorporateEmployeesForm from "@/components/Customers/Corporate/Employees/CorporateEmployeesForm.vue";
import CustomerCorporateOrdersPause from "@/components/Customers/Corporate/Actions/CustomerCorporateOrdersPause";
import CustomerEmployeesDelete from "../Actions/CustomerEmployeesDelete";
import EmployeesFundWallet from "@/components/Customers/Corporate/Employees/FundWallet/EmployeesFundWallet";
import CustomerCorporateChangeStatus from "../Actions/CustomerCorporateChangeStatus.vue";
import CustomerCorporateBatchOrderAdjustment from "../Actions/CustomerCorporateBatchOrderAdjustment.vue";

export default {
  components: {
    CustomerEmployeesDelete,
    CorporateEmployeesExport,
    CorporateEmployeesForm,
    CustomerCorporateOrdersPause,
    EmployeesFundWallet,
    CustomerCorporateChangeStatus,
    CustomerCorporateBatchOrderAdjustment,
  },
  name: "CustomerCorporateEmployees.vue",
  props: {
    company: {
      type: Object,
      default() {
        return {};
      },
    },
    customerType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      uploading: false,
      page: 1,
      showPagination: true,
      employeeData: [],
      employeeId: "",
      employee: {},
      customer: {},
      exporting: false,
      searchText: "",
      employeeForm: false,
      showEmployeeFundWallet: false,
      showBulkEmployeeAdd: false,
      showCustomerCorporateDelete: false,
      showChangeStatus: false,
      showBatchOrder: false,
      pageDataTime: "",
      selectedEmployees: [],
      showEmployeePauses: false,
      employeeDetails: {
        action: "delete",
        visibility: false,
        data: {},
      },
      csv: {
        title: "Bulk Customers Upload",
        templateUrl:
          "https://docs.google.com/spreadsheets/d/1xUDb0oHE84tohz4w5L-pMWKQifrCAWblv9bV7gYZgRU/edit?usp=sharing",
        fields: [
          "First Name",
          "Last Name",
          "Email",
          "Phone Number",
          "Home Address",
          "Location Area",
          "Landmark",
          "Gender",
          "Gardener",
          "Start Date",
        ],
        url: `${process.env.VUE_APP_API}customer/bulkupload`,
      },
    };
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    },
    employees() {
      if (this.searchText !== "") {
        return this.employeeData.filter((employee) => {
          const search_text = this.searchText.toLowerCase();
          return (
            employee.name.toLowerCase().includes(search_text) ||
            employee.email.toLowerCase().includes(search_text)
          );
        });
      }
      return this.employeeData;
    },
  },
  created() {
    this.getEmployees();
  },
  mounted() {
    this.employeeData = this.employeeData.map((employee) =>
      this.formatToTitleCase(employee.name),
    );
  },
  methods: {
    updateCustomerData(updatedData) {
      this.employeeDetails.data = updatedData; // To fetch the updated data again, if needed
    },
    getEmployees(callback) {
      this.loading = true;
      corporate
        .getEmployees(this.companyId)
        .then((response) => {
          this.loading = false;
          // this.employees = response.data;
          this.employeeData = response.data.data.map((employee) => {
            return {
              ...employee,
              services: employee.next_billing_date.length
                ? Object.keys(employee.next_billing_date[0].plan)
                : [],
            };
          });
          this.pageDataTime = new Date();
          callback ? callback() : false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getLocationAreaId(locationAreaName) {
      const locations = this.$store.getters.location_areas_list;
      const location = locations.filter(
        (location) =>
          location.name.split(" ").join("-").toLowerCase() ===
          locationAreaName.split(" ").join("-").toLowerCase(),
      );
      if (location.length) {
        return location[0].id;
      } else {
        return process.env.NODE_ENV === "production" ? 122 : 944;
      }
    },
    uploadCustomers(csv) {
      const csvArrayOfObject = [];
      csv.splice(0, 1);
      csv.forEach((item) => {
        csvArrayOfObject.push({
          FullName: item[0] + " " + item[1],
          Email: item[2],
          PhoneNumber: item[3],
          HomeAddress: item[4],
          LocationArea: this.getLocationAreaId(item[5]),
          Landmark: item[6],
          Gender: item[7].toLowerCase().split(" ").join("-"),
          Gardener: item[8],
          StartDate: this.customerType === "one-time" ? "" : item[9],
        });
      });

      let payload = {
        csv: csvArrayOfObject,
        plan: JSON.stringify(this.company.plan),
        user_corporation_id: parseInt(this.companyId),
        is_one_time_customer: this.customerType === "one-time",
      };

      corporate
        .addEmployees(payload)
        .then((response) => {
          if (response.data.status) {
            this.uploading = false;
            this.showBulkEmployeeAdd = false;
            this.$message.success(response.data.message);
            this.updateEmployeesList();
          }
        })
        .catch((error) => {
          this.uploading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    exportEmployees() {
      this.exporting = true;
    },
    search(text) {
      this.searchText = text;
    },
    addEmployee(command) {
      if (command === "bulk") {
        this.showBulkEmployeeAdd = true;
        this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
      } else {
        this.employeeForm = true;
      }
    },
    updateEmployeesList() {
      this.getEmployees(this.updateSubscriptionAmount);
    },
    updateSubscriptionAmount() {
      const subscriptionId = this.company.subscription_id;
      const subscriptionAmount = this.employees
        .filter((employee) => employee.customer_status === "subscriber")
        .reduce((total, employee) => {
          const employeeSubscriptionAmount = employee.next_billing_date
            ? employee.next_billing_date[0].amount
            : 0;
          return total + employeeSubscriptionAmount;
        }, 0);

      const payload = {
        amount: subscriptionAmount,
      };

      subscriptions
        .update(subscriptionId, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateEmployees() {
      this.employees.splice(this.employee.index, 1);
      this.updateSubscriptionAmount();
    },
    setSelectedEmployees(employees) {
      this.selectedEmployees = [];
      employees.forEach((employee) => {
        this.selectedEmployees.push(employee.id);
      });
    },
    isNotChurned(row) {
      return row.customer_status !== "churned";
    },
    command(command) {
      switch (command.action) {
        case "add":
        case "fund-wallet":
          this.employeeDetails.data = this.employees[command.index];
          this.showEmployeeFundWallet = true;
          break;
        case "delete":
          this.employeeDetails.data = this.employees[command.index];
          this.showCustomerCorporateDelete = true;
          break;
        default:
          break;
      }
    },
    getPauses() {
      this.getEmployees();
    },
  },
};
</script>
